@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

.md-custom-container {
	@apply md:container md:max-w-screen-lg md:mx-auto;
}
